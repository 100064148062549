/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
try {
  var triggerBttn = document.getElementById( 'trigger-overlay' ),
      overlay = document.querySelector( 'div.overlay' ),
      closeBttn = overlay.querySelector( 'button.overlay-close' );
      transEndEventNames = {
          'WebkitTransition': 'webkitTransitionEnd',
          'MozTransition': 'transitionend',
          'OTransition': 'oTransitionEnd',
          'msTransition': 'MSTransitionEnd',
          'transition': 'transitionend'
      },
      transEndEventName = transEndEventNames[ Modernizr.prefixed( 'transition' ) ],
      support = { transitions : Modernizr.csstransitions };

  function toggleOverlay() {
      if( classie.has( overlay, 'open' ) ) {
          classie.remove( overlay, 'open' );
          classie.add( overlay, 'close' );
          var onEndTransitionFn = function( ev ) {
              if( support.transitions ) {
                  if( ev.propertyName !== 'visibility' ) return;
                  this.removeEventListener( transEndEventName, onEndTransitionFn );
              }
              classie.remove( overlay, 'close' );
          };
          if( support.transitions ) {
              overlay.addEventListener( transEndEventName, onEndTransitionFn );
          }
          else {
              onEndTransitionFn();
          }
      }
      else if( !classie.has( overlay, 'close' ) ) {
          classie.add( overlay, 'open' );
      }
   }


 triggerBttn.addEventListener( 'click', toggleOverlay );
 closeBttn.addEventListener( 'click', toggleOverlay );
 } catch (e){}

 function displayProfile(profileId) {
     jQuery('[id$=meet-the-team-overlay]').css('display', 'none');
     jQuery('#'+ profileId).css('display', 'block');
 }

 function contactDetailsColour(colour) {
     jQuery('.contact-details > h3').css('color', colour);
     jQuery('.contact-details > h4').css('color', colour);
 }

 function getNavWidth() {
     var width = jQuery(window).width();
     var navPercentage;

     if (width >= 1500) {
         navPercentage = "40%";
     } else if (width >= 1000) {
         navPercentage = "45%";
     } else if (width >= 800) {
         navPercentage = "60%";
     } else {
         navPercentage = "100%";
         jQuery('#mySidenav').css('text-align', 'center');
     }

     return navPercentage;
 }

 function set_add_to_map_button_colour(buttonColor) {
     var borderBottom;
     var backgroundColor;
     var textColor;

     if (buttonColor == 'blue') {
         jQuery(".put-me-on-the-map-handle").removeClass("put-me-on-the-map-button-green");
         jQuery(".put-me-on-the-map-handle").addClass("put-me-on-the-map-button");
     } else if (buttonColor == 'green') {
         jQuery(".put-me-on-the-map-handle").removeClass("put-me-on-the-map-button");
         jQuery(".put-me-on-the-map-handle").addClass("put-me-on-the-map-button-green");
     }
 }

  function openNav() {
      var navWidth = getNavWidth();
      document.getElementById("mySidenav").style.width = navWidth;
      jQuery('.burger-icon > img').attr("src","/wp-content/uploads/2017/08/cross.png");
      jQuery('#mySidenav').css('padding-right', "9%");

      set_add_to_map_button_colour('green');
      contactDetailsColour('black');

      jQuery('.burger-icon').click(function() {
          closeNav();
      });
  }

  function closeNav() {
      document.getElementById("mySidenav").style.width = "0";
      jQuery('#mySidenav').css('padding-right', "0%");
      jQuery('.burger-icon > img').attr("src","/wp-content/uploads/2017/08/burger-icon.png");
      jQuery('.burger-icon.mobile > img').attr("src","/wp-content/uploads/2017/08/burger-icon-green.png");
      contactDetailsColour('white');
      set_add_to_map_button_colour('blue');

      jQuery('.burger-icon').click(function() {
          openNav();
      });
  }


  // initialize owl slider on testimonials
   function initOwlSlider() {
       var owlDanbro = jQuery("#owl-danbro");

       owlDanbro.owlCarousel({
           autoplay: true,
           loop: true,
           autoplayTimeout: 7000,
           items: 1,
           itemsDesktop: [1199, 3],
           itemsDesktopSmall: [979, 3],
           navigation: true,
           navigationText: false,
           animateIn: 'fadeIn',
           animateOut: 'fadeOut',
           mouseDrag: false,
           touchDrag: false,
       });
   }


(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        jQuery('.burger-icon').click(function() {
            openNav();
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        initOwlSlider();
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
